/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";
import Component from "./Component";

export type WidgetButton = Extract<WidgetUnion, { __typename: "WidgetButton" }>;

export interface Props extends WidgetProps {
  widget: WidgetButton;
}

const ButtonWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      containerSize={containerSize}
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Component isNested={isNested} widget={widget} />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetButton on WidgetButton {
    id
    __typename
    buttonShape {
      alignment
      label
      link
      size
    }
  }
`;

export default memo(ButtonWidget);
