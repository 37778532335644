/** @jsx jsx */
import type {
  WidgetButtonAlignment,
  WidgetButtonSize,
} from "gatsby-theme-pages/__generated__/gatsby.types";
import type { Props } from "gatsby-theme-pages/src/components/Widget/Button/Component";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { jsx } from "theme-ui";

const getFlexAlignment = (
  alignment: WidgetButtonAlignment | null | undefined
) => {
  if (alignment === "left") {
    return "flex-start";
  }
  if (alignment === "right") {
    return "flex-end";
  }
  if (alignment === "center") {
    return "center";
  }

  return undefined;
};

const getSizeStyles = (size?: WidgetButtonSize | null) => {
  if (size === "small") {
    return {
      fontSize: 0,
      paddingX: "1em",
      paddingY: 1,
      minHeight: "2rem",
    };
  }

  if (size === "big") {
    return {
      fontSize: 3,
      paddingX: "1.5em",
      paddingY: 3,
      minHeight: "4.5rem",
    };
  }

  return {};
};

const ButtonWidgetComponent: React.FC<Props> = ({ widget }) => {
  const shape = widget.buttonShape;

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: getFlexAlignment(shape?.alignment),
      }}
    >
      <Link
        sx={{
          variant: "buttons.primary",
          ...getSizeStyles(widget.buttonShape?.size),
        }}
        title={shape?.label || undefined}
        to={shape?.link || undefined}
      >
        {shape?.label}
      </Link>
    </div>
  );
};

export default memo(ButtonWidgetComponent);
